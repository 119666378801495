import React, { useEffect, useState } from "react"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { graphql, PageProps } from "gatsby"
import getSearchParam from "@ecom/ui/utils/getSearchParam"

import Layout from "../../components/Layouts/mainPage"
import { NewHeader } from "../../components/Headers/NewHeader"
import { Banner as BannerOrder } from "../../components/Banners/OrderIndex"
import { Banner as RoundedDynamicBanner } from "../../components/Banners/DynamicBanners/RoundedDynamicBanner"
import { HowToGetCard } from "../../components/HowToGetCard"
import { FormPKW } from "../../components/FormPKW"
import { NewCalculator } from "../../components/NewCalculator/NewCalculator"
import { MoreBenefitsRedisign } from "../../components/MorBenefitsRedisgn"
import { FourAdvantagesPoints } from "../../components/FourAdvantagesPoints"
import { OtherCards } from "../../components/OtherCards"
import { BottomCta, RefinSeoBgImg } from "../../components/BottomCta"
import { Partners } from "../../components/PartnersNew"
import { Reviews } from "../../components/Reviews"
import { Questions } from "../../components/Questions"
import { NewFooter } from "../../components/NewFooter"

import { getPageData } from "../../helpers/getPageData"

import { BannerText, getDynamicBannerText } from "../../helpers/getDynamicBannerText"
import { getDynamicBannerUrl } from "../../helpers/getDynamicBannerUrl"

import { PageData } from "../../interfaces/pageProps"
import { QUESTIONS_FOR_HALVA_PKW } from "../../components/Questions/helpers"
import { STEPS_MEDIA_D } from "../../components/HowToGetCard/helpers"

const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const defaultTitleMob = `
Рассрочка до 24 мес. </br>
по карте «Халва»
`

const defaultTitle = `
Рассрочка до 24 месяцев </br>
по карте «Халва»
`

const bannerDescription = `
— До 3% к ставке вклада
<br />
— Кэшбэк до 10%
<br />
— Обслуживание 0 ₽ 
`

const subTitlePartners = <>Любые покупки у партнеров в&nbsp;рассрочку</>

const GIFT_TITLE = (
  <>
    Оформите Халву и получите
    <br />
    <b> месяц&nbsp;подписки&nbsp;в&nbsp;подарок</b>
  </>
)

// Время для таймер(30мин)
const countDownTime = 1800000

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)
  const [dynamicBannerTextObj, setDynamicBannerTextObj] = useState<BannerText>()
  const [dynamicBannerImagesObj, setDynamicBannerImagesObj] = useState<any>()

  const isMob = useMediaQuery("(max-width:599.5px)")
  const finalBannertitle = isMob ? defaultTitleMob : defaultTitle

  const bannerTextUrl = getSearchParam("h")
  const bannerImageUrl = getSearchParam("bn")

  useEffect(() => {
    if (bannerImageUrl) {
      const bannerImagesData = getDynamicBannerUrl(data.allDynamicBanner, bannerImageUrl)
      if (bannerImagesData !== null) {
        setDynamicBannerImagesObj(bannerImagesData)
      }
    }
  }, [data.allDynamicBanner, bannerImageUrl])

  useEffect(() => {
    if (bannerTextUrl) {
      getDynamicBannerText(bannerTextUrl).then((result) => {
        setDynamicBannerTextObj(result)
      })
    }
  }, [bannerTextUrl])

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  const [longForm, setLongForm] = useState(false)

  useEffect(() => {
    if ((globalThis as any).KameleoonExperiment_271113 === 1024325) {
      setLongForm(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(globalThis as any).KameleoonExperiment_271113])

  return (
    <Layout
      noHeader
      noFooter
      noIndex={noIndex}
      seoData={{
        title: "Рассрочка от 10 месяцев по карте Халва",
      }}
    >
      <NewHeader redLogoWithSovcombank />
      {dynamicBannerImagesObj && bannerImageUrl ? (
        <RoundedDynamicBanner
          large={dynamicBannerImagesObj.dymanicBannerDesk}
          mob={dynamicBannerImagesObj.dymanicBannerMob}
          title={dynamicBannerTextObj ? dynamicBannerTextObj.title : finalBannertitle}
          description={dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription}
          orderNum="1"
        />
      ) : (
        <BannerOrder
          variantStyle="indexPkw"
          isPkw
          title="Рассрочка от 10 месяцев по карте «Халва»"
          orderNum="1"
          titleMob="Рассрочка от&nbsp;10&nbsp;месяцев по&nbsp;карте «Халва»"
        />
      )}
      <FourAdvantagesPoints
        withTitle
        indent
        variant="newYearMedia"
        title="Преимущества с Халвой:"
        orderNum="3"
      />
      <FormPKW
        progressBar
        dataLayerName={longForm ? "longPersonalForm" : "shortPersonalForm"}
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        longForm={longForm}
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        orderNum="4"
        onlyPKWPage
        isGreenApprove
      />
      <HowToGetCard items={STEPS_MEDIA_D} orderNum="5" />
      <NewCalculator additionalEventInDataLayer orderNum="6" />
      <OtherCards orderNum="7" />
      <Partners
        title="Более 260&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="8"
      />
      <MoreBenefitsRedisign
        additionalEventInDataLayer
        orderNum="9"
        hasSubtitle
        bottomBlock={false}
      />
      <BottomCta variant="pkwRedesign" title={GIFT_TITLE} BgImg={RefinSeoBgImg} orderNum="10" />
      <Reviews additionalEventInDataLayer orderNum="11" />
      <Questions questionList={QUESTIONS_FOR_HALVA_PKW} orderNum="12" />
      <NewFooter ligal={ligal} orderNum="13" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/pkw/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
